const Level2Charger = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1434 28.251C19.8806 28.251 20.4989 27.6908 20.5765 26.9525L21.697 16.2893L21.3215 5.56786C21.295 4.8111 20.7212 4.18849 19.9737 4.10548L14.7314 3.52325C14.621 3.51099 14.5096 3.51099 14.3993 3.52325L9.15692 4.10548C8.40948 4.18849 7.83567 4.8111 7.80916 5.56786L7.4336 16.2893L8.55416 26.9526C8.63173 27.6908 9.25007 28.251 9.98727 28.251H19.1434ZM4.9043 16.3787L5.28612 5.47824C5.35681 3.46021 6.88697 1.79991 8.88016 1.57855L14.1225 0.996318C14.4168 0.96363 14.7138 0.96363 15.0081 0.996318L20.2505 1.57855C22.2437 1.79991 23.7738 3.46021 23.8445 5.47825L23.9866 9.53351L26.8315 9.58099C27.5501 9.56873 28.2265 9.92751 28.6251 10.5347L32.0312 15.7416C32.2299 16.046 32.2917 16.4085 32.2238 16.7474C33.4561 18.1555 34.1022 19.9804 34.1022 21.8377V31.1844C34.1022 35.4763 30.6755 38.9718 26.4233 38.9718H20.7132C16.4611 38.9718 13.0343 35.4763 13.0343 31.1844V30.7933H9.98727C7.95861 30.7933 6.25705 29.2516 6.04357 27.2201L4.9043 16.3787ZM15.5589 30.7933V31.1844C15.5589 34.0926 17.8754 36.4296 20.7132 36.4296H26.4233C29.2611 36.4296 31.5776 34.0926 31.5776 31.1844V21.8377C31.5776 20.5698 31.1375 19.3554 30.336 18.4355C30.0007 18.438 29.6648 18.3107 29.4072 18.0537L26.5144 15.1651C26.1219 14.7726 25.5904 14.5518 25.0378 14.5518H24.1623L24.2263 16.3787L23.0871 27.2201C22.8736 29.2516 21.172 30.7933 19.1434 30.7933H15.5589ZM19.2725 14.8724L15.6218 14.8325L15.6852 8.87122L9.85742 17.4727L13.5081 17.5126L13.4507 22.8943L19.2725 14.8724Z"
      fill="#38393A"
    />
    <path
      d="M19.2725 14.8722L15.6218 14.8324L15.6852 8.87109L9.85742 17.4726L13.5081 17.5125L13.4507 22.8942L19.2725 14.8722Z"
      fill="#0EDAB5"
    />
  </svg>
);
export default Level2Charger;
