const BatteryChargerNA = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="75"
    height="72"
    viewBox="0 0 75 72"
    fill="none"
  >
    <path
      d="M35.6948 63.9883H7.41239V14.4942H35.6948M38.0634 7.42362H32.1595V0.353027H10.9477V7.42362H5.04374C2.46297 7.42362 0.341797 9.54479 0.341797 12.1256V66.357C0.341797 68.9377 2.46297 71.0589 5.04374 71.0589H38.0634C40.6795 71.0589 42.7653 68.9731 42.7653 66.357V12.1256C42.7653 9.54479 40.6442 7.42362 38.0634 7.42362ZM74.583 28.6354H63.9771V3.88832L46.3006 39.2413H56.9065V67.5236"
      fill="#CCCCD1"
    />
  </svg>
);
export default BatteryChargerNA;
