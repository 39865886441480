const OilBarWhite = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="55"
    viewBox="0 0 43 55"
    fill="none"
  >
    <path
      d="M38.7677 42.8953L38.7691 42.893C40.5688 39.8799 41.5 36.6498 41.5 33.1565C41.5 30.7879 40.8214 28.1015 39.534 25.0985C38.2535 22.1118 36.7125 19.3306 34.9076 16.7945L34.8872 16.7659L34.8655 16.7382C33.2821 14.7207 31.416 12.4972 29.268 10.1029C27.1081 7.69511 23.3619 4.0104 22.5062 3.16873L22.4986 3.16131L21.4427 2.1227L20.3908 3.16541L15.8607 7.65591C13.5991 9.80999 11.416 12.2811 9.34661 15.1022C7.27085 17.9319 5.44904 20.9772 3.91822 24.2375C2.29478 27.5433 1.5 30.5252 1.5 33.1565C1.5 36.634 2.38757 39.9041 4.19974 42.9001C5.99387 45.8663 8.40952 48.2819 11.4729 50.0521C14.5345 51.8213 17.8435 52.7307 21.4113 52.7307C24.9769 52.7307 28.3182 51.8225 31.4122 50.0569C34.4489 48.3363 36.9837 45.8698 38.7677 42.8953Z"
      fill="#38393A"
      stroke="white"
      strokeWidth="3"
    />
  </svg>
);
export default OilBarWhite;
