import { ReactNode } from 'react';
import { TypeOptions, toast } from 'react-toastify';

type ShowToastOptions = { onClick?: (event: React.MouseEvent) => void } & (
  | { type: Extract<TypeOptions, 'success' | 'info'> }
  | {
      type: Extract<TypeOptions, 'error'>;
      onRetry?: (() => Promise<void>) | (() => void);
    }
);

export const showToast = (
  content: string | ReactNode,
  options: ShowToastOptions
): void => {
  const { type, onClick } = options;
  const showButton = type !== 'success';

  toast(content, {
    type,
    onClick,
    closeButton: showButton
      ? ({ closeToast }) => (
          <button
            className={`mr-xl text-body2Regular ${
              type === 'error' ? 'text-red-medium' : ''
            }`}
            onClick={async (e) => {
              closeToast(e);
              if (type === 'error' && !!options.onRetry) {
                await options.onRetry();
              }
            }}
          >
            {type === 'error' && !!options.onRetry ? 'Retry' : 'Close'}
          </button>
        )
      : undefined,
  });
};
