const Level3Charger = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M4.99967 36.6663V11.6663C4.99967 7.73801 4.99967 5.77301 6.21967 4.55301C7.43967 3.33301 9.40467 3.33301 13.333 3.33301H18.333C22.2613 3.33301 24.2263 3.33301 25.4463 4.55301C26.6663 5.77301 26.6663 7.73801 26.6663 11.6663V36.6663M3.33301 36.6663H28.333M4.99967 18.333H26.6663M33.6897 13.3097L34.9998 12.3663C34.9998 12.3663 35.9363 11.408 36.0597 11.2097C36.1847 11.0097 36.6663 11.7063 36.6663 11.2097C36.6663 10.138 36.6663 9.60134 36.3763 9.35467C36.2843 9.27625 36.1762 9.21881 36.0597 9.18634C35.6897 9.08301 35.238 9.38134 34.3363 9.97634C33.178 10.7397 32.5997 11.1213 32.2297 11.6663C32.1078 11.8474 32.0039 12.04 31.9197 12.2413C31.6663 12.8463 31.6663 13.533 31.6663 14.9113V17.633C31.6663 18.0197 31.983 18.333 32.3747 18.333C32.6565 18.3338 32.9322 18.2505 33.1665 18.0937C33.4007 17.9369 33.5829 17.7139 33.6897 17.453V13.3097Z"
      stroke="#38393A"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.4627 18.333C32.796 20.643 33.5743 23.283 33.2577 25.633C32.9727 27.7613 31.2877 29.4797 29.071 29.9013C28.5544 29.9997 27.9243 29.9997 26.666 29.9997"
      stroke="#38393A"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4151 27.0011L16.7644 26.9613L16.8278 21L11 29.6015L14.6507 29.6414L14.5933 35.0231L20.4151 27.0011Z"
      fill="#0EDAB5"
    />
  </svg>
);
export default Level3Charger;
